import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from "gatsby"

import { Routes } from '@constants/routes';

import TwitterIcon from '@images/twitterIconBlack.svg';
import LinkedinIcon from '@images/linkedinIconBlack.svg';
import FacebookIcon from '@images/facebookIconBlack.svg';
import InstagramIcon from '@images/instagramIconBlack.svg';
import {
  TwitterUrl,
  LinkedinUrl,
  FacebookUrl,
  InstagramUrl,
  SupportUrl,
  NewsUrl
} from '@constants/app';

export default () => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(filter: {title: { eq: "logoUpFamily" }}) {
				edges {
					node {
						title
						file {
							url
						}
					}
				}
      }
    }
  `);

  const ctf = data.allContentfulAsset;
  const upFamilyLogoUrl = ctf.edges[0].node.file.url;

  return (
    <footer className="pt-5 pb-5 bg-light text-light" id="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <Link
              to="/"
              className="navbar-brand d-none d-md-block"
            >
              <img src={upFamilyLogoUrl} alt="logo" className="mb-0" />
            </Link>
            <div className="w-100 d-flex d-md-none px-5 pb-5 justify-content-between border-bottom border-dark">
              <a href={TwitterUrl}>
                <TwitterIcon width="40" height="40"/>
              </a>
              <a href={LinkedinUrl}>
                <LinkedinIcon width="40" height="40"/>
              </a>
              <a href={FacebookUrl}>
                <FacebookIcon width="40" height="40"/>
              </a>
              <a href={InstagramUrl}>
                <InstagramIcon width="40" height="40"/>
              </a>
            </div>
          </div>
          <div className="col-md-2">
            <ul className="m-0 p-0 text-center text-md-left">
              <li className="mb-2">
                <p className="m-0 p-0 text-dark font-semibold mt-5 mt-md-0">Our Products</p>
              </li>
              <li className="mb-2">
                <Link className="text-dark" to="/trade-up">Trade-Up</Link>
              </li>
              <li className="mb-2">
                <Link className="text-dark" to="/work-up">Work-Up</Link>
              </li>
              <li className="mb-2">
                <Link className="text-dark" to="/service-up">Service-Up</Link>
              </li>
              <li className="mb-2">
                <Link className="text-dark" to="/check-up">Check-Up</Link>
              </li>
              <li className="mb-2">
                <Link className="text-dark" to="/project-up">Project-Up</Link>
              </li>
              <li className="mb-2">
                <Link className="text-dark" to="/up-family">Up-Family</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2">
            <ul className="m-0 p-0 text-center text-md-left mt-5 mt-md-0">
              <li className="mb-2">
                <p className="text-dark font-semibold m-0">Company</p>
              </li>
              <li className="mb-2">
                <Link className="text-dark font-medium" to={Routes.About}>About Us</Link>
              </li>
              <li className="mb-2">
                <Link className="text-dark font-medium" to={Routes.OurClients}>Our Clients</Link>
              </li>
              {/*<li className="mb-2">
                <Link className="text-dark font-medium" to={Routes.OurPartners}>Our Partners</Link>
              </li>*/}
              <li className="mb-2">
                <Link className="text-dark font-medium" to={Routes.CaseStudies}>Case Studies</Link>
              </li>
              <li className="mb-2">
                <a href={NewsUrl} className="text-dark font-medium">News</a>
              </li>
            </ul>
          </div>
          <div className="col-md-2">
            <ul className="m-0 p-0 text-center text-md-left mt-5 mt-md-0">
              <li className="mb-2">
                <p className="text-dark font-semibold m-0">Support</p>
              </li>
              <li className="mb-2">
                <Link className="text-dark font-medium" to={Routes.ContactUs}>Contact Us</Link>
              </li>
              <li className="mb-2">
                <a href={SupportUrl} className="text-dark font-medium">Support</a>
              </li>
            </ul>
          </div>
          <div className="col-md-2">
            <div className="flex-column h-100 justify-content-between d-none d-md-flex">
              <a href={TwitterUrl}>
                <TwitterIcon/>
              </a>
              <a href={LinkedinUrl}>
                <LinkedinIcon/>
              </a>
              <a href={FacebookUrl}>
                <FacebookIcon/>
              </a>
              <a href={InstagramUrl}>
                <InstagramIcon/>
              </a>
            </div>
            <Link
              to="/"
              className="navbar-brand d-flex justify-content-center align-items-center d-md-none w-100 p-5"
            >
              <img src={upFamilyLogoUrl} alt="logo" className="mb-0" />
            </Link>
          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col-md-4 text-center text-md-left">
            <p className="text-dark font-medium my-2">Copyright © {new Date().getFullYear()} Up Family</p>
          </div>
          <div className="col-md-2 text-center text-md-left">
            <p className="text-dark font-medium my-2">All rights reserved.</p>
          </div>
          <div className="col-md-2 text-center text-md-left">
            <Link
              to={Routes.Privacy}
              className="d-block font-medium text-dark my-2"
            >
              Privacy
            </Link>
          </div>
          <div className="col-md-2 text-center text-md-left">
            <Link
              to={Routes.TermsOfUse}
              className="d-block font-medium text-dark my-2"
            >
              Terms of Use
            </Link>
          </div>
          <div className="col-md-2 text-center text-md-left">
            <Link
              to={Routes.Cookies}
              className="d-block font-medium text-dark my-2"
            >
              Cookies
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
