import React, { useRef, useEffect, useState } from "react"
import classnames from 'classnames';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useStaticQuery, graphql, Link } from "gatsby"

import { Routes } from '@constants/routes';
import { NewsUrl, OldTradeUpUrl, EnquireUrl, LoginUrl, SignupUrl, SupportUrl } from '@constants/app';
import HamburgerIcon from '@images/hamburgerIcon.svg';

import './header.scss';

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref,cb]);
}

const sidebarLinks = [
  {
    name: 'Our Products',
    link: Routes.Discover
  },
  {
    name: 'About Us',
    link: Routes.About
  },
  {
    name: 'Our Clients',
    link: `${Routes.CaseStudies}#ourclients`
  },
];

const Header = ({ siteTitle }) => {

  const [ isOpenProducts, setOpenProducts ] = useState(false);
  const [ isVisibleSideMenu, setVisibleSideMenu ] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(filter: {title: { eq: "logoUpFamily" }}) {
				edges {
					node {
						title
						file {
							url
						}
					}
				}
      }
    }
  `);

  const ctf = data.allContentfulAsset;
  const upFamilyLogoUrl = ctf.edges[0].node.file.url;

  const toggleProducts = () => setOpenProducts(!isOpenProducts);
  const toggleSideMenu = () => setVisibleSideMenu(!isVisibleSideMenu);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    if (isVisibleSideMenu) {
      toggleSideMenu();
    }
  });

  return (
    <React.Fragment>
      <div 
        ref={wrapperRef}
        className={classnames("sidemenu", isVisibleSideMenu ? 'sidemenu-visible' : 'sidemenu-hidden')}
      >
        <ul className="list-unstyled p-3">
          { sidebarLinks.map((item, i) => (
            <li key={i} className="w-100 py-3 border-bottom border-secondary">
              <Link
                to={item.link}
                className="text-dark font-medium"
              >
                {item.name}
              </Link>
            </li>
          ))}
          <li className="w-100 py-3 border-bottom border-secondary">
            <a
              href={NewsUrl}
              className="text-dark font-medium"
            >
              News
            </a>
          </li>
          <li className="w-100 py-3 border-bottom border-secondary">
            <a
              href={SupportUrl}
              className="text-dark font-medium"
            >
              Support
            </a>
          </li>
          <li className="w-100 py-3 border-bottom border-secondary">
            <Link
              to={Routes.ContactUs}
              className="text-dark font-medium"
            >
              Contact Us
            </Link>
          </li>
          <li className="w-100 py-3 border-bottom border-secondary">
            <a
              href={LoginUrl}
              className="text-dark font-medium"
            >
              Login
            </a>
          </li>
          <li className="w-100 py-3 border-bottom border-secondary">
            <a
              href={SignupUrl}
              className="text-dark font-medium"
            >
              Sign Up
            </a>
          </li>
          <li className="w-100 py-3 border-bottom border-secondary">
            <a
              href={EnquireUrl}
              className="text-primary font-medium"
            >
              Enquire
            </a>
          </li>
        </ul>
      </div>
      <header className="d-block d-lg-none" style={{ height: '70px' }}>
        <div className="h-100">
          <div className="h-100 d-flex align-items-center justify-content-between">
            <div>
              <HamburgerIcon
                className="ml-5 cursor-pointer"
                onClick={() => toggleSideMenu()}
              />
            </div>
            <Link
              to="/"
              className="navbar-brand mr-5"
            >
              <img src={upFamilyLogoUrl} alt="logo" className="m-0" style={{ height: '30px' }}/>
            </Link>
          </div>
        </div>
      </header>
      <header className="d-none d-lg-block" style={{ height: '80px' }}>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-white pl-5 pr-5 pt-3 pb-3">
            <div className="collapse navbar-collapse px-0 px-lg-2 flex-fill" id="navbarSupportedContent">
              <Link
                to="/"
                className="navbar-brand"
              >
                <img src={upFamilyLogoUrl} alt="logo" className="m-0"/>
              </Link>
              <ul className="navbar-nav mr-auto">
                <li className="nav-item dropdown flex-center mb-0">
                  <Dropdown isOpen={isOpenProducts} toggle={toggleProducts}>
                    <DropdownToggle caret tag="span" className="cursor-pointer mr-2 nav-link">
                      Our Products
                    </DropdownToggle>
                    <DropdownMenu>
                      <div className="d-flex">
                        <div className="border-right" style={{ minWidth: '200px' }}>
                          <div className="d-flex flex-column p-3">
                            <p 
                              className="font-weight-bold text-capitalize text-nowrap mb-1 pl-1"
                            >Discover</p>
                            <Link
                              to={Routes.DiscoverBuilders}
                              className="nav-link text-nowrap pl-1"
                            >For Builders</Link>
                            <Link
                              to={Routes.DiscoverSubcontractors}
                              className="nav-link text-nowrap pl-1"
                            >For Subcontractors</Link>
                            <Link
                              to={Routes.DiscoverSiteWorkers}
                              className="nav-link text-nowrap pl-1"
                            >For Site Workers</Link>
                            <Link
                              to={Routes.DiscoverCompliance}
                              className="nav-link text-nowrap pl-1"
                            >For Compliance</Link>
                            <Link
                              to={Routes.Discover}
                              className="nav-link text-nowrap pl-1"
                            >
                              View All Products
                            </Link>
                          </div>
                        </div>
                        <div className="border-right" style={{ minWidth: '200px' }}>
                          <div className="d-flex flex-column p-3">
                            <p 
                              className="font-weight-bold text-capitalize text-nowrap mb-1 pl-1"
                            >Product Range</p>
                            <Link
                              to={Routes.TradeUp}
                              className="nav-link text-nowrap pl-1"
                            >Trade Up</Link>
                            <Link
                              to={Routes.WorkUp}
                              className="nav-link text-nowrap pl-1"
                            >Work Up</Link>
                            <Link
                              to={Routes.ServiceUp}
                              className="nav-link text-nowrap pl-1"
                            >Service Up</Link>
                            <Link
                              to={Routes.ProjectUp}
                              className="nav-link text-nowrap pl-1"
                            >Project Up</Link>
                            <Link
                              to={Routes.CheckUp}
                              className="nav-link text-nowrap pl-1"
                            >Check Up</Link>                           
                          </div>
                        </div>
                        <div className="border-right" style={{ minWidth: '200px' }}>
                          <div className="d-flex flex-column p-3">
                            <p 
                              className="font-weight-bold text-capitalize text-nowrap mb-1 pl-1"
                            >Resources</p>
                            <a
                              href={SupportUrl}
                              className="nav-link text-nowrap pl-1"
                            >Support</a>
                            <Link
                              to={Routes.CaseStudies}
                              className="nav-link text-nowrap pl-1"
                            >Case Studies</Link>
                            <a
                              href={NewsUrl}
                              className="nav-link text-nowrap pl-1"
                            >News</a>
                          {/*<Link
                              href={Routes.Releases}
                              className="nav-link text-nowrap pl-1"
                            >Releases</Link>*/}
                          </div>
                        </div>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </li>
                <li className="nav-item mb-0">
                  <Link
                    to={Routes.About}
                    className="nav-link text-nowrap font-medium"
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item mb-0">
                  <Link
                    to={`${Routes.CaseStudies}#ourclients`}
                    className="nav-link text-nowrap font-medium"
                  >
                    Our Clients
                  </Link>
                </li>
                <li className="nav-item mb-0">
                  <Link
                    to={Routes.ContactUs}
                    className="nav-link text-nowrap font-medium"
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item mb-0">
                  <a
                    href={SupportUrl}
                    className="nav-link text-nowrap font-medium"
                  >
                    Support
                  </a>
                </li>
              </ul>
            </div>
            <div className="collapse navbar-collapse justify-content-end flex-fill col px-0">
              <a href={OldTradeUpUrl} className="btn btn-sm btn-orange ml-4">Trade Up V1</a>
              <a href={LoginUrl} className="btn btn-sm btn-outline-dark ml-4">Login</a>
              <a href={SignupUrl} className="btn btn-sm btn-primary ml-4">Sign Up</a>
            </div>
          </nav>
        </div>
      </header>
    </React.Fragment>
  )
};

export default Header
