export const Routes = {
  TradeUp: '/trade-up',
  WorkUp: '/work-up',
  ServiceUp: '/service-up',
  CheckUp: '/check-up',
  ProjectUp: '/project-up',
  UpFamily: '/up-family',
  About: '/about',
  CaseStudies: '/case-studies',
  OurClients: '/case-studies#ourclients',
  ContactUs: '/contact-us',
  Privacy: '/legal/privacy',
  TermsOfUse: '/legal/terms',
  Cookies: '/legal/cookies',
  Discover: '/discover',
  DiscoverBuilders: '/discover/builders',
  DiscoverSubcontractors: '/discover/subcontractors',
  DiscoverSiteWorkers: '/discover/siteworkers',
  DiscoverCompliance: '/discover/compliance'
};
